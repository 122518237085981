/* scss variables */
@use '@css/SharedVariables.scss' as *;

#navigation-bar {
    text-align: center;
    font-family: 'Inter';
    font-size: 1rem;
    font-style: normal;
    line-height: 100%; /* 25.5px */
    display: flex;
    gap: 5px 10px;
    flex-wrap: wrap;
    align-items: center;

    .avatar {
        border-radius: 4px;
        background: $a1;
        box-shadow: 0px 2px 4px 0px $shadow2;
        width: 32px;
        height: 32px;
        overflow: hidden;
        cursor: pointer;
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            opacity: .8;
        }
    }

    button {
        background: linear-gradient(to top, mix(#000, $a1, 25%), $a1);
        box-shadow: 0px 2px 4px 0px $shadow2;
        text-shadow: 0px 2px 4px $shadow1;
        font-weight: 700;
        color: $a1-color;
        padding: 0 15px;
        display: grid;
        align-items: center;
        display: inline-block;
        font-size: .8rem;
        text-transform: lowercase;
        height: 100%;
        height: 32px;
        &:hover { background-color: unset;}
        box-sizing: border-box;
    }

    #theme-controls {
        flex: 1;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: flex-end;
        gap: 1ch;
        height: 32px;
    }

    .sheet-links {
        color: color-mix(in srgb, var(--mode-0, #fefefe) 10%, var(--text-color));
        cursor: pointer;
        transition: 0.14s linear;
        font: 400 0.9rem / 1 "Inter";
        letter-spacing: .02em;
        display: flex;
        column-gap: 7px;
    }

    #continue-grp {
        margin-top:0;
    }
}