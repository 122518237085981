/* scss variables */
@use '@css/SharedVariables.scss' as *;

.wrapper {
    display: block;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: $m1;
    overflow: auto;
    scroll-behavior: smooth;
    padding: min(38px, 2.6388888889vw) min(71px, 4.9305555556vw);
    scrollbar-gutter: stable;
    color: $color;
}
::-webkit-scrollbar {
    width: 10px;
  }
::-webkit-scrollbar-track {
    background: mix($a1, $bg, 10%);
}
::-webkit-scrollbar-thumb {
    background: $a1;
    transition: .44s ease;
}
::-webkit-scrollbar-thumb:hover {
    background: darken($a1, 20%);
    cursor: pointer;
}

.innerwrapper {
    max-width: 1220px;
    margin: 0 auto;
}