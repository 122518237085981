/* scss variables */
@use '@css/SharedVariables.scss' as *;

/* reset css */
@import url('https://cdn.jsdelivr.net/npm/reset-css@5.0.2/reset.min.css');

/* font families */
@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
  /* accents */
  --accent1: #3f51b5;
  --accent1-color: #{$lightest};

  /* constants */
  --light: #{$lightest};
  --dark: #{$darkest}; 

  /* functionalities */
  --background-color: #{$light-3};
  --text-color: #{$dark-5};
  --text-size: 16;

  /* neutrals */
  --mode-0: #{$light-0};
  --mode-1: #{$light-3};
  --mode-2: #{$light-1};

  --neutral-1: #{$dark-1};
}

[theme-mode=dark] {
  /* functionalities */
  --background-color: #{$dark-3};
  --text-color: #{$light-2};

  /* neutrals */
  --mode-0: #{$dark-0};
  --mode-1: #{$dark-3};
  --mode-2: #{$dark-4};

  --neutral-1: #{$light-4};
}

body {
  /* tooltips */
  --rt-color-white: #{$m0};
  --rt-color-dark: #{$n1};
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
  --rt-transition-show-delay: 0.15s;
  --rt-transition-closing-delay: 0.15s;
}

.App {
  background-color: $m2;
  font: 400 rem(1)/1.4 'Inter';
  color: $color;
}

.hidden-svg {
  height: 0;
  width: 0;
  position: absolute;
  top: 0; 
  left: 0;
}

a {
  all: unset;
  cursor: pointer;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}

select, input, button, textarea {
  margin: 0;
  padding: 0;
  border: 1.5px solid $m1;
  display: inline-block;
  padding: 6px;
  background: alpha($m1, 50%);
  transition: .22s ease;
  box-sizing: border-box;
  color: $color;
  &:hover {
    background-color: mix($n1, $m2, 5%);
  }
}

input[type=submit] {
  padding: 6px 21px
}

button {
  background-color: $a1;
  color: $a1-color;
  font: 700 rem(.78)/1 'Inter';
  text-transform: uppercase;
  letter-spacing: -.02em;
  padding: 10px 14px;
  transition: .2s ease;
  border: unset;
  border-radius: 2px;
  &:hover {
    background-color: mix($m0, $a1, 13%);
  }
}