@use '@css/SharedVariables.scss' as *;

#ability{
    .innerwrapper {
        max-width: 950px;
    }
    .ability-container {
        margin: 0px 0 11px;
        background-color: $m0;
        border-radius: 0;
        padding: 0 20px 25px;
        gap: 25px;
        &#set-scores {
            display: grid;
            grid-template-columns: repeat(3, auto);
        }
        h1 {
            grid-column: 1 / -1;
            padding: 20px 25px;
            margin: 0 -20px 0;
            background: linear-gradient(to top, darken($a1, 25%), $a1);
            color: $a1-color;
            font: 600 rem(.88)/1 "Inter";
        }
        .abilityscore{
            display: grid;
            text-align: center;
            label {
                border: 1px solid $m1;
                border-top: 0px;
                text-transform: uppercase;
                padding: 12px 4px;
                font: 400 rem(.8)/1 'Poppins';
                letter-spacing: .02em;
            }
            input {text-align: center;}
            input::placeholder {text-align: center;}
        }
        .proficiency {
            margin: 0 -20px -25px;
        } 
    }
    #roll-btns {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        grid-column: 1 / -1;
    }
    #roll-result {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        button {
            background-color: $m2;
            color: $color;
            cursor: initial;
            box-shadow: 0 0 2px $shadow2;
        }
        .rollresult {
            background-color: $m2;
            color: $color;
            box-shadow: 0 0 2px $shadow2;
            font: 700 calc(var(--text-size, 16)* 0.78* 1px) / 1 "Inter";
            text-transform: uppercase;
            letter-spacing: -0.02em;
            padding: 10px 14px;
            transition: 0.2s ease;
            border: unset;
            border-radius: 2px;
        }
    }
}

@media screen and (max-width: 570px){ #ability #set-scores { grid-template-columns: repeat(2, auto); } }
@media screen and (max-width: 400px){ #ability #set-scores { grid-template-columns: repeat(1, auto); } }