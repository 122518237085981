/* scss variables */
@use '@css/SharedVariables.scss' as *;

.details  {
    background-color: $m0;
    .summary {
        transition: .2s ease;
        padding: 20px 25px;
        background: linear-gradient(to top, darken($a1, 25%), $a1);
        color: $a1-color;
        font: 600 rem(.88)/1 "Inter";
        cursor: pointer;
    }
    .details-container {
        transition: .48s ease-out;
        overflow: hidden;
        padding: 0;
        &.hide { transition: .45s ease; }
    }
}
.details + .details {
    .summary { border-top: 3px solid $m2; }
}