/* scss variables */
@use '@css/SharedVariables.scss' as *;

#continue-grp {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 7px;
}
#continue-btn {
    cursor: pointer;
    transition: 0.14s linear;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    display: flex;
    column-gap: 7px;
    font-size: rem(.92)
}