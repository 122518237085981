/* scss variables */
@use '@css/SharedVariables.scss' as *;

.modal {
    position: fixed;
    top:0;left:0;
    width: 100dvw;height: 100dvh;
    background: alpha($m1, 70%);
    display: grid;
    place-items: center;
    z-index: 10;


    .inner-modal {
        width: min(600px, 70%);
        margin: 0 auto;
        background: $m1;
        color: $color;
        border-radius: 3px;
        box-shadow: 0 0 4px $shadow2;
    }

    .details {
        padding: 30px 30px 25px;
        display: block;
        box-sizing: border-box;
        text-align: center;
    
        b {
          margin: 0 0 0 3px;
          font-weight: 700;
          letter-spacing: .02em;
        }
    }
    .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    button {
        padding: 13px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        font-weight: 600;
        transition: .14s ease;
        color: $n1;
        background-color: $m0;
        &:hover {
            background: darken($m0, 4%)
        }
    }
    .btn-yes {
        background: $a1;
        color: $a1-color;
      &:hover {background: darken($a1, 6%)}
    }
    }
}