/* ==================
 SCSS VARIABLES
================== */

/* constants */
$light-0: #fefefe;
$light-1: #F9F9F9;
$light-2: #F4F4F4;
$light-3: #F1F1F1;
$light-4: #D5D5D9;

$dark-0: #202020;
$dark-1: #2C2C2C;
$dark-2: #2F2F2F;
$dark-3: #313235;
$dark-4: #3D3D3D;
$dark-5: #3F3F3F;

$lightest: $light-2;
$darkest: $dark-0;
$darker: $dark-2;

/* neutrals */
$m0: var(--mode-0, $light-0);
$m1: var(--mode-1, $light-3);
$m2: var(--mode-2, $light-1);

$n1: var(--neutral-1, $dark-1);

/* accents */
$a1: var(--accent1, #3f51b5);
$a1-color: var(--accent1-color, $lightest);

/* functionals */
$shadow1: rgba(15, 15, 15, 25%);
$shadow2: rgba(0, 0, 0, 6%);
$bg: var(--background-color);
$color: var(--text-color); 


/* ==================
 FUNCTIONS FOR CSS VARIABLES
================== */

@function alpha($color, $amount: 50%) {
    @return color-mix(in srgb, $color $amount, transparent);
}

@function mix($color1, $color2, $amount: 50%) {
    @return color-mix(in srgb, $color1 $amount, $color2);
}

@function rem($multi: 1) {
    @return calc(var(--text-size, 16) * $multi * 1px);
} 

/*
    these are absolute functions 
    for elements that are meant to adjust per light/dark mode, better to use mix + the neutrals 
*/
@function lighten($color, $amount: 50%) {
    @return color-mix(in srgb, $lightest $amount, $color);
}

@function darken($color, $amount: 50%) {
    @return color-mix(in srgb, $darkest $amount, $color);
}

