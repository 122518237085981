/* scss variables */
@use '@css/SharedVariables.scss' as *;

#scale-control {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 6px;
    align-items: center;
    .fa-regular {
        font-size: .6rem;
        cursor: pointer;
        transition:.3s ease;
        &:hover {
            color: mix($m0, $color, 25%)
        }
    }
}