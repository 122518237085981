/* scss variables */
@use '@css/SharedVariables.scss' as *;

#inventory {
    .innerwrapper {
        max-width: 950px;
    }
    #inventory-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 4px;
    }
    .inv-box {
        background: $m0;
        border-radius: 4px 4px 0 0;
        margin: 3px 0 8px;
        padding-bottom: 10px;
        h1{
            padding: 20px 25px;
            border-radius: 4px 4px 0 0;
            background: linear-gradient(to bottom, mix($darkest, $m0, 10%), $m0);
            color: $color;
            font: 600 rem(.88) / 1 "Inter";
        }
        .entry {
            padding: 5px 13px;
        }
    }
    .details {
        grid-column: 1/span 2;
    }
}