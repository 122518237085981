.page {
    box-sizing: border-box;
    height: 100dvh;
    width: 100%;
    padding: min(20px, 20/1000*100vw);
    gap: 7px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0 auto;
}
.page:has(header) {
    padding: 5px min(20px, 20/1000*100vw) min(20px, 20/1000*100vw);
}