/* scss variables */
@use '@css/SharedVariables.scss' as *;

#navigation-bar {
    #btns-group {
        height: 100%;
        margin-left: -3px;
        width: 0px;
        box-sizing: border-box;
        overflow: hidden;
        transition: 0.3s ease-out;
        > span {
            white-space: nowrap;
            display: inline-flex;
            align-items: center;
            height: 100%;
            gap: 5px;
        }
        &.hide { 
            transition: 0.25s ease-out;
        }
    }
    #logout-btn, #edit-btn {
        background: $n1;
        padding: 0;
        color: $m0;
        display: inline-block;
        span {
            width: fit-content;
            padding: 0 15px;
        }
    }
    #edit-btn {
        background: mix($m0, $n1, 20%)
    }
}