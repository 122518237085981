/* scss variables */
@use '@css/SharedVariables.scss' as *;

#landing {
    .wrapper { padding: initial; }
    .innerwrapper {
        max-width: unset;
    }
    
    #main {
        background: linear-gradient(to bottom, #fff, alpha($a1, 4%)), $bg;
    }
    
    /* ==================
        BANNER
    ================== */
    #banner {
        display: grid;
        place-items: center;
        grid-template:
        'top'
        'title'
        'bottom' / 100%;
    }
    #BannerGradient {
        background: linear-gradient(to bottom, rgba(214, 214, 214, 20%), rgba(255, 255, 255, 0)), darken($a1, 30%);
        width: 100%;
        height: min(339/1400 * 100vw, 339px);
        position: relative;
        overflow: hidden;
        grid-area: top;
        svg {
            position: absolute;
            bottom: 0;
            left: 0;
            fill: $darkest;
            height: min(284/1400 * 100vw, 284px);
            width: 100%;
        }
    }
    #BannerStrip {
        width: 100%;
        background-color: $darkest;
        z-index: 0;
        position: relative;
        text-transform: uppercase;
        color: $lightest;
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: auto 0px;
        justify-content: center;
        padding-bottom: min(84/1400 * 100vw, 84px);
        grid-area: title;
        h1 {
            font: bold min(140/1400 * 100vw, 140px)/1 'Poppins';
            letter-spacing: -0.08em;
            align-self: flex-end;
        }
        h2 {
            font: italic 400 min(44/1400 * 100vw, 44px)/1 'Poppins';
            align-self: flex-start;
            grid-row: 2;
            text-align: center;
        }
        img {
            height: min(400/1400 * 100vw, 400px);
            width: min(400/1400 * 100vw, 400px);
            grid-row: 1/span 2;
            grid-column: 2/span 1;
            place-self: center;
            z-index: -1;
            transform: rotate(-2.9deg);
            position: absolute;
        }
    }
    #BannerBottom {
        width: 100%;
        height: 140/1400 * 100vw;
        background: $darkest;
        clip-path: url(#banner_bottom);
        grid-area: bottom;
    }
    #BannerButtons {
        border-radius: 15px 15px 50px 50px;
        overflow: hidden;
        grid-area: bottom;
        height: min(94/1400 * 100vw, 94px);
        width: min(498/1400 * 100vw, 498px);
        display: grid;
        grid-template-columns: 1fr 1fr;
        box-shadow: 0 6px 15px $shadow1;
        position: relative;
        top: -94/1400 * 50vw;
        text-transform: uppercase;
        a {
            font: 800 min(31/1400 * 100vw, 31px)/1 'Poppins';
            display: grid;
            place-items: center;
            text-decoration: none;
            color: $lightest;
            &#register {
                background-color: $a1;
            }
            &#login {
                background-color: $darker;
            }
        }
    }
    
    /* ==================
        ABOUT
    ================== */
    #about {
        font: 400 clamp(rem(1), 32/1400*100vw, rem(2))/1.4 "Inter";
        letter-spacing: .1em;
        color: $color;
        padding: max(188/1400*100vw, 25px) 238/1400*100vw;
        .dice {
            font-size: rem(5.625);
            text-align: right;
            display: block;
        }
        p {
            display: block;
            margin: 14px 0;
        }
        strong {
            font-weight: bold;
        }
    }
    
    /* ==================
        FEATURES
    ================== */
    #PreviewsHeader {
        $image1: "https://placehold.co/1395x755";
        background: url($image1) top center / cover;
        display: grid;
        grid-template: "round" "shadow" 1fr / 100%;
        height: min(755/1400*100vw, 755px);
        .tuckedstrip {
            grid-area: round;
            background-color: mix($a1, $bg, 4%);
            height: min(117/1400*100vw, 117px);
            border-radius: 0 0 50px 50px;
        }
        .clippedimage {
            grid-area: round;
            background: url($image1) top center / cover;
            clip-path: url(#features_image_clip);
            height: min(154/1400*100vw, 154px);
        }
        .shadowgrad {
            grid-area: shadow;
            background:linear-gradient(to bottom, transparent, $darkest)
        }
    }
    #Previews {
        background: $darkest;
        padding: 0 min(141/1400*100vw, 141px) min(141/1400*100vw ,141px);
        display: grid;
        grid-template-columns: 100%;
        gap: min(100/1400*100vw, 100px);
    
        title {
            display: block;
            margin: 0;
            font: bold clamp(rem(1), 98/1400*100vw, rem(6.125))/1 "Poppins";
            text-align: right;
            position: relative;
            top: -3px;
            letter-spacing: .04em;
            text-transform: uppercase;
            color: $lightest;
            margin-bottom: -10px;
        }
    
        .featuresbox {
            display: grid;
            grid-template: "image sep desc" / 1fr 126/1400*100vw 1fr;
            &:nth-of-type(even) {
                grid-template-areas: "desc sep image";
            }
            color: $lightest;
            font: rem(1)/1.5 'Inter';
            letter-spacing: .06em;
            font-variation-settings: 'slnt' -3;
        }
        .featimgbox {
            grid-area: image;
            width: 100%;
            display: grid;
            grid-template-areas: "a";
            align-items: center;
            justify-content: flex-end;
            position: relative;
            align-self: flex-start;
            &::after {
                content:"";
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $lightest;
                grid-area: a;
                position: absolute;
                right: calc(-126/1400*50vw - 5px);
            }
        }
        .featuresbox:nth-of-type(even) .featimgbox {
            justify-content: flex-start;
            &::after {
                right: unset;
                left: calc(-126/1400*50vw - 5px);
            }
        }
        .featuredimage {
            object-fit: cover;
            object-position: center;
            width: 100%;
            border-radius: 15px;
            grid-area: a;
        }
        .separator {
            justify-self: center;
            height: 100%;
            width: 0px;
            border-left: 1px dotted $dark-5;
            display: grid;
            place-items: center;
            grid-area: sep;
        }
        .description {
            grid-area: desc;
            h1 {
                text-transform: uppercase;
                font: 500 rem(1) / 1 "Poppins";
                display: block;
                margin-bottom: 4px;
                color: lighten($a1, 15%);
            }
        }
    
    }
}