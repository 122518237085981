/* scss variables */
@use '@css/SharedVariables.scss' as *;

.selection-inputs {
    display: grid;
    &:has(.descriptions) {
        grid-template-columns: 34% 1fr;
    }
    .inputs {
        display: grid;
        height: 100%;
        overflow: auto;
        label {
            input[type=radio], > input[type=checkbox] {display: none;}
            padding: 9px 15px;
            cursor: pointer;
            transition: .4s ease;
            &:hover {
                background-color: alpha($a1, 3%);
            }
            &:has(:checked) {
                background-color: alpha($a1, 9%);
                font-weight: 520;
            }
        }
    }
    .descriptions:has(.description) {
        padding: 11px 18px;
        height: 0;
        min-height: 100%;
        grid-column: 2/span 1;
    }
    .description {
        display: none;
        height: 100%;
        overflow: auto;
    }
    .description.active {
        display: block;
    }
    .description p + p {
        margin: 12px 0;
    }
}

@media screen and (max-width: 590px) {
    .selection-inputs {
        height: 80vh;
        grid-template-rows: 60% 1fr;
        &:has(.descriptions) {
            grid-template-columns: 100%;
        }
        .descriptions:has(.description) {
            border-top: 2px solid $m2;
            grid-column: 1;
        }
    }
}