@use '@css/SharedVariables.scss' as *;

#charimage {
  .innerwrapper {
    max-width: 950px;
  }
  #charimage-form {
    background: $m0;
    padding: 25px;
    border-radius: 5px 5px 0 0;
    min-height: 300px;
    width: 100%;
  }
  #button-panel {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 7px;

    #continue-grp {
      margin-top: 0px
    }
  }
  #ReqAttrs {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 15px 0;
    justify-content: center;
  }

  .form-element {
    display: grid;
    justify-items: center;
    gap: 7px;
  }
  #avatar_result {
    border-radius: 10%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    max-width: 100%;
    img{
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    &:before {
        content: 'Change image';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: alpha($n1, 75%);
        opacity: 0;
        display: grid;
        place-items: center;
        color: $m0;
        font-weight: 450;
        transition: .25s ease;
    }
    &:hover:before {
        opacity: 1;
    }
  }
  #avatar {
    display: block;
    background-color: $m0;
  }
}