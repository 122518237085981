@use '@css/SharedVariables.scss' as *;

#character {
    .innerwrapper {
        max-width: 950px;
    }
    #name {
        width: 100%;
        background-color: $m0;
        padding: 12px 18px;
        margin-bottom: 4px;
    }
}