/* scss variables */
@use '@css/SharedVariables.scss' as *;

#preferences {

/* ==================
    PREFERENCES FORM
================== */

#user-pref {
    width: 100%;
    background-color: $m2;
    border-radius: 8px 8px 1px 1px;
    box-shadow: 0 4px 4px $shadow2;
    max-width: 800px;
    margin: 0 auto;
}

/* ==================
    USER INFO
================== */

#user-pref-header {
    display: grid;
    grid-template: 'a a' 'field btn' / 1fr auto;
    gap: 30px 11px;
    justify-items: center;
    padding: 50px 55px 30px;
}
#user-avatar {
    background-position: center;
    background-size: cover;
    height: 175px;
    width: 175px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    grid-area: a;
    &:before {
        content: 'Change avatar';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: alpha($n1, 75%);
        opacity: 0;
        border-radius: 50%;
        display: grid;
        place-items: center;
        color: $m0;
        font-weight: 450;
        transition: .25s ease;
    }
    &:hover:before {
        opacity: 1;
    }
}
#set-avatar {
    border: 1.5px solid $m1;
    grid-area: field;
    width: 100%;
}
#update-avatar-btn {
    grid-area: btn;
}

#user-info {
    padding: 50px 55px 30px;
    border: 2px solid $m1;
    border-left: 0; border-right: 0;
}
#user-info label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px 15px;
    width: 100%;
}
#set-name {
    border-width: 0px;
    border-bottom: 1.5px solid mix($n1, $m1, 15%);
    padding: 6px 14px;
    flex: 1;
    width: 100%;
    min-width: 100px;
}

/* ==================
    THEME SETTINGS
================== */

#theme-info {
    display: grid;
    grid-template: 'label1 label2' 'color color';
    place-items: center;
    margin: 40px 0 30px;
}
#theme-info label {
    
    input { display: none; }
}
.circle-picker {
    grid-area: color;
    width: unset !important;
    margin: unset !important;
}



/* ==================
    SUBMISSION
================== */
#submission {
    padding: 30px 55px 50px;
}



}