/* scss variables */
@use '@css/SharedVariables.scss' as *;

.modal {
    background-color: $m1;
}
/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    height: 50px;
    display: flex;
    transform: rotate(45deg);
    animation: l3-0 1.5s infinite linear;
    margin: 0 auto;
}
.loader:before,
.loader:after {
    content: "";
    width: 50%;
    background: $a1;
    clip-path: polygon(0 50%,100% 0,100% 100%);
    animation: inherit;
    animation-name: l3-1;
}
.loader:after {
    clip-path: polygon(0 0,100% 50%,0% 100%);
    animation-name: l3-2;
}
@keyframes l3-0 {
    25% {width:50px;height:50px;transform:rotate(0)}
    50% {width:50px;height:50px}
    75% {width:70.70px;height:35.35px}
    100%{width:70.70px;height:35.35px;transform:rotate(0)}
}
@keyframes l3-1 {
    0%,25% {clip-path: polygon(0 50% ,100% 0,100% 100%);transform:translate(0.3px)}
    50%    {clip-path: polygon(0 50% ,100% 0,100% 100%);transform:translate(-5px)}
    75%    {clip-path: polygon(0 100%,0    0,100% 100%);transform:translate(-5px)}
    100%   {clip-path: polygon(0 100%,0    0,100% 100%);transform:translate(17.7px)}
}
@keyframes l3-2 {
    0%,25% {clip-path: polygon(0 0,100% 50%,0    100%);transform:translate(-0.3px) }
    50%    {clip-path: polygon(0 0,100% 50%,0    100%);transform:translate(5px) }
    75%    {clip-path: polygon(0 0,100% 0  ,100% 100%);transform:translate(5px)}
    100%   {clip-path: polygon(0 0,100% 0  ,100% 100%);transform:translate(-17.7px) }
}