/* scss variables */
@use '@css/SharedVariables.scss' as *;

#navigate-back {
    color: mix($m0, $color, 40%);
    cursor: pointer;
    transition: .14s linear;
    text-transform: uppercase;
    font: 400 .76rem/1 'Inter';
    letter-spacing: -.02em;
    display: flex;
    column-gap: 7px;
    &:hover { color: $color }
}