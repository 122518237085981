/* scss variables */
@use '@css/SharedVariables.scss' as *;

.error, .updated {
    color: alpha($color, 90%);
    letter-spacing: .05em;
    cursor: unset;
    font: 600 rem(.78)/1 'Inter';
    padding: 10px 14px;
    transition: .2s ease;
    border: unset;
    display: inline-block;
    box-sizing: border-box;
}

.error {
    background-color: mix( var(--rt-color-error), $m1, 11%);
    border: 1.5px solid mix( var(--rt-color-error), $m1, 15%)
}
.updated {
    background-color: mix( var(--rt-color-success), $m1, 11%);
    border: 1.5px solid mix( var(--rt-color-success), $m1, 15%)
}