@use '@css/SharedVariables.scss' as *;

#backstory {
  .innerwrapper {
    max-width: 950px;
  }
  #backstory-form {
    background: $m0;
    padding: 25px;
    border-radius: 5px 5px 0 0;
    min-height: 300px;
    width: 100%;
  }
  #button-panel {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 7px;

    #continue-grp {
      margin-top: 0px
    }
  }
  #ReqAttrs {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 15px 0;
  }
}