/* scss variables */
@use '@css/SharedVariables.scss' as *;

#home {

.innerwrapper {
    display: grid;
    gap: 28px;
    grid-template-columns: 100%;
}

/* ==================
    WELCOME BANNER
================== */

#welcome {
    background: $m0;
    padding: 33px 36px;
    font: 600 rem(1.5)/1 'Inter';
    font-variation-settings: 'slnt' -3;
    color: mix($m0, $color, 15%);
    box-shadow: 0 4px 4px $shadow2;
}

/* ==================
    FEATURED CHARACTER (LATEST)
================== */

#latest-character {
    background-color: $m0;
    box-shadow: 0 4px 4px $shadow2;
    width: 100%;
}
#featured-character {
    background-color: $a1;
    aspect-ratio: 2;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        opacity: .45;
    }
}


/* ==================
    CHARACTER LIST
================== */

#character-list {
    display: grid;
    grid-template: "search list" / 320px auto;
    gap: clamp(5px, 28/1440*100vw, 28px);
    align-items: flex-start;
}

/* ==================
    SEARCH
================== */

#search {
    background: $m0;
    box-shadow: 0px 4px 4px 0px $shadow2;
    position: sticky;
    top: 0px;
    grid-area: search;
    z-index: 2;
}
#searchbar-title {
    background-color: mix($a1,$m0,19%);
    padding: 15.5px 2ch;
    color: $color;
    font-family: Inter;
    font-size: rem(1);
    font-weight: 600;
    font-variation-settings: 'slnt' -5;
    line-height: 100%;
    letter-spacing: -.02em;
    text-transform: uppercase;
    transition: .36s ease;
    cursor: pointer;
    &:hover {
        transition: .25s ease;
        background-color: mix($a1,$m0,27%);
        transform: scale(1.034);
    }
}
.search-ico {margin-right: 1ch;}
.search-container {
    height: 0;
    transition: .48s ease-out;
    overflow: hidden;
    &.hide { transition: .45s ease; }
}
#search-body {
    display: flex;
    flex-wrap: wrap;
    padding: 28px 23px;
    gap: 10px;
    label, input, select {
        width: 100%;
    }
}
#search h2 {
    color: $color;
    font-family: "Inter";
    font-size: rem(10/16);
    font-weight: 600;
    line-height: 100%; 
    letter-spacing: -.01em;
    text-transform: uppercase;
    margin-left: 2ch;
    margin-bottom: 2px;
}
.search-type {width:100%}
.sorting {flex: 1 1 120px;}

/* ==================
    CHARACTER CARDS
================== */

#characters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-area: list;
    gap: 12px 29px;
}
.card {
    background-color: $m0;
    color: $color;
    display: grid;
    grid-template: "main" "bar" / 100%;
    word-wrap: initial;
    text-transform: uppercase;
    font: rem(1)/1 "Inter";
    border: 0;
    border-radius: 3px;
    box-shadow: 0 3px 4px $shadow2; 
}
.card .background {
    grid-area: main;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template: "image grad" / auto 1fr;
    grid-area: main;
    overflow: hidden;
    &::before{
        content: "";
        grid-area: image;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, alpha($a1, 33%), alpha($a1, 100%));
        display: block;
        position: relative;
    }
    img {
        grid-area: image;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .char-grad-overlay {
        grid-area: grad;
        background: linear-gradient(to right, alpha($a1, 100%), $m0);
        aspect-ratio: 1;
        height: 100%;
    }
}
.card .info {
    grid-area: main;
    display: grid;
    grid-template-columns: calc(60% - 10px) 40%;
    grid-template-rows: min-content;
    align-content: flex-end;
    width: 100%;
    gap: 10px;
    padding-bottom: 7px;
    box-sizing: border-box;
    position: relative;

    .character-status{
        display: grid;
        align-content: flex-end;
        width: calc(100% - 8px);
        grid-template-columns: 100%;
    }
    .character-name {
        height: 0px;
        min-height: 100%;
        color: $a1-color;
        text-align: right;
        text-shadow: 0px 4px 4px $shadow1;
        font-family: "Inter";
        font-weight: 900;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        align-items: flex-end !important;
        padding-left: 8px;
        box-sizing: border-box;
    }
    .level { color: $a1-color; font-weight: 700; letter-spacing: -.08em; align-items: flex-end !important; div {line-height: 80%;} }
    .character-class { color: mix($m0, $a1-color, 10%); align-items: flex-end !important; font-weight: 650; letter-spacing: -.05em; div {line-height: 80%;} }

}
.card .options {
    grid-area: bar;
    border-top: $m1 1px solid;
    background: $m0;
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    font-size: rem(.9);
    button { 
        color: unset;
        border: unset;
        font-weight: unset;
        background: inherit;
        text-transform: capitalize;
        flex: 1;
        padding: 19px 10px;
        transition: .22s ease;
        &:hover {
            background: mix($m1, $m0, 50%);
        }
    }
}

}

@media screen and (max-width: 485px) {
    #home #character { grid-template-columns: 100%; }
}
@media screen and (max-width: 900px) {
    #home #character-list { grid-template: "search" "list" / 100%; }
    #home #search { top: max(-25px, -25/1440*100vw) }
}