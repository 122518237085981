@use '@css/SharedVariables.scss' as *;

#view {
    .innerwrapper {
        max-width: 950px;
    }

    #form-view {
        display: grid;
        grid-template: 
            'top top'
            'score details' min-content
            'prof details' 1fr / 35% 1fr;
        gap: 6px;
        align-items: flex-start;
    }

    #basic-info {
        grid-area: top;
        h1 {
            padding: 24px 18px 18px;
            background: $darkest;
            color: $lightest;
            border-radius: 6px 6px 0 0;
            font: 800 rem(3) 'Poppins';
            position: relative;
            overflow: hidden;
            z-index: 0;
            .ico {
                position: absolute;
                font-size: 140px;
                top:0px;
                z-index: -1;
                color: $a1;
                opacity: .2;
            }
        }
        #character-name {line-height: 1;}
        .sub-bar{
            background: linear-gradient(to bottom, mix($a1, $darker, 12%), mix($a1, $darker, 30%));
            padding: 14px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px 11px;
            justify-content: right;
            align-items: center;
            span {
                background-color: $a1;
                color: $a1-color;
                text-transform: uppercase;
                font: 700 rem(.85)/1 'inter';
                letter-spacing: -.02em;
                padding: 11px 14px;
                border-radius: 2.5px;
            }
            #continue-grp {
                margin-top:0;
                flex: 1;
            }

        }
    }
    #scores {
        grid-area: score;
        display: grid;
        gap: 7.5px;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        .stat {
            border: 1.5px solid $a1;
            display: inline-block;
            background: alpha($m1,50%);
            transition: 0.22s ease;
            box-sizing: border-box;
            color: $color;
        }
        .value {
            text-transform: uppercase;
            padding: 12px 4px;
            font: 400 rem(.8)/ 1 "Poppins";
            letter-spacing: 0.02em;
        }
        .label {
            border-top: 1.5px solid $a1;
            font: 600 rem(.8)/ 1 "Poppins";
            text-transform: uppercase;
            padding: 9px;
            background-color: alpha($a1, 50%);
            color: $a1-color;
        }
    }
    #proficiencies-list {
        grid-area: prof;
        border: 1px solid alpha($a1, 30%);
        .prof {
            display: grid;
            grid-template-columns: 1fr 40px;
            background-color: alpha($a1, 10%);
            box-shadow: 0 0 1px $a1;
            align-items: center;
        }
        .value {
            text-align: center;
            padding: 6px 8px;
            background-color: alpha($a1, 50%);
            color: $a1-color;
            font: 600 rem(.8)/ 1 "Poppins";
        }
        .label {padding: 6px 8px; font: 400 rem(.9)/ 1 "Inter";}
    }

    #details-boxes {
        grid-area: details;

        .inv{
            padding: 5.5px 8px;
            box-shadow: 0 0 1px $a1;
        }

        #backstory-inner {
            padding: 31px;
        }

        #detailimage{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}


@media screen and (max-width: 600px) {
    #view #scores { grid-template-columns: repeat(2, 1fr);}
    #view #form-view {
        grid-template: "top top"
        "score score" min-content
        "prof prof" min-content
        "details details "/ 35% 1fr
    }
}

@media screen and (max-width: 490px) {
    #view #scores { grid-template-columns: repeat(1, 1fr);}
}