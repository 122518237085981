@use '@css/SharedVariables.scss' as *;

#access {
    .innerwrapper {
        max-width: 600px;
    }
    h1 {
        transition: .2s ease;
        padding: 20px 25px;
        background: linear-gradient(to top, darken($a1, 25%), $a1);
        color: $a1-color;
        font: 600 rem(1)/1 "Inter";
    }
    .form-element {
        .inner-form {
            background-color: $m0;
            padding: 25px;
            display: grid;
            gap: 17px;
            grid-template-columns: 100%;
        }
        label {
            margin-bottom: -16px;
            padding-left: 1ch;
            font: 450 rem(.9)/1 "Inter";
            color: mix($m0, $color, 10%)
        }
    }
}